import React, { useEffect, useState } from 'react';
import Card from '../../components/board/Card';
import styled from 'styled-components';

import { db } from '../../helpers/firebase';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  /* gap: 10px; */
`;

export default function BoardContainer() {
  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    onSnapshot(
      query(collection(db, 'users'), orderBy('createdAt', 'desc')),
      (querySnapshot) => {
        setUsers(querySnapshot.docs.map((doc) => doc.data()));
      }
    );
  }, []);
  useEffect(() => {
    console.log({ users });
  }, [users]);
  return (
    <div style={{ padding: '0 10vw ' }}>
      <div style={{}}>
        <CardContainer id="card-container">
          {users.map((user) => {
            return (
              <Card
                key={user.username}
                twitterID={user.username}
                imageURL={user.imageUrl}
              />
            );
          })}
        </CardContainer>
      </div>
    </div>
  );
}
