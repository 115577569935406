import React from 'react';
import { isMobile } from 'react-device-detect';
import { Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { rem } from 'polished';

import Social from './Social';
import { FluidGrid } from '../layouts/global-styles';
import { FaDiscord, FaMedium, FaTwitter } from 'react-icons/fa';

const Wrapper = styled.footer`
  padding: ${rem(12)} 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  letter-spacing: 0;
  line-height: 1;
  color: ${(props) => props.theme.colors.white};
  text-align: left;

  &:empty {
    display: none;
  }

  @media all and (min-width: 581px) {
    position: relative;
    padding: ${rem(30)} 0;
  }
`;

const SocialContainer = styled.div`
  font-size: ${rem(17)};
  line-height: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const SocialButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 10px;
  font-size: 1.5rem;
`;

const Disclaimer = styled.div`
  font-size: ${rem(14)};
  line-height: normal;
  opacity: 0.5;
  text-align: center;
`;

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Grid as={FluidGrid} fluid>
        <SocialContainer>
          Join Soul Fiction
          <SocialButtonContainer>
            <a href="https://discord.gg/bEMb3NZrCc" target="_blank" rel="noreferrer">
              <FaDiscord />
            </a>
            <a href="https://twitter.com/SoulFictionNFT" target="_blank" rel="noreferrer">
              <FaTwitter />
            </a>
            <a href="https://twitter.com/SoulFictionNFT" target="_blank" rel="noreferrer">
              <FaMedium />
            </a>
          </SocialButtonContainer>
        </SocialContainer>
        <Disclaimer>
          The collective NFT art experiment
          <br />
          aims to bridge the world of Art x AI x Human Imagination
        </Disclaimer>
      </Grid>
    </Wrapper>
  );
};

export default Footer;
