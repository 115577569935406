import styled from 'styled-components';
import { rem } from 'polished';

/**
 * Types
 */
export interface InputProps {
  isValid: boolean,
}

const TextInput = styled.input<InputProps>`
  position: relative;
  background-color: transparent;
  color: white;
  border: 1px solid ${(props)=> props.isValid?"white":"red"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  transition:  0.3s ease 0s;
  border-radius: 3px;    
  }

  &:hover {
    border: 1px solid ${(props)=> props.isValid? props.theme.colors.primary:"red"};
    color: rgb(162, 165, 191);
  }

  &:focus{
    outline: none;
    border: 1px solid ${(props)=> props.isValid? props.theme.colors.primary:"red"};
  }

  i {
    font-size: ${rem(48)};
    margin: ${rem(-7)} ${rem(15)} ${rem(-7)} ${rem(-15)};
  }

  i,
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  @media all and (max-width: 580px) {
    /* border-radius: 12px; */
    padding: 8px 16px;
    width: 100%;
    font-size: 14px;
    line-height: normal;

    i {
      font-size: 32px;
    }
  }
`;

export default TextInput;
