import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

/**
 * Types
 */
export interface CardProps {
  twitterID: string;
  imageURL: string;
}

const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-out;

  @media all and (max-width: 580px) {
    border-radius: 16px;
  }

  &:hover {
    filter: drop-shadow(0 0 0.75rem ${(props) => props.theme.colors.primary});
    .img{
      top: -10px;
    }
  }
`;

const IDText = styled.p`
  margin: 0 0 2rem 0;
  font-size: 1.4rem;
`

const Card = ({ twitterID, imageURL }: CardProps) => {
  return (
    <CardBox onClick={()=>{window.open(`https://twitter.com/${twitterID}`)}}>
      <img src={imageURL} width="250px"></img>
      <IDText>{twitterID}</IDText>
    </CardBox>
  );
};

export default Card;
