import { isNil } from "ramda"

import { SCALE_FACTOR, STAGE_CONFIG } from "./const"

import { FigureConfig } from "./types"

export const scale = (image?: HTMLImageElement): FigureConfig | null => {
  if (isNil(image)) {
    return null
  }

  const ratio = Math.min(STAGE_CONFIG.width / image.width, STAGE_CONFIG.height / image.height)
  const x = STAGE_CONFIG.width / SCALE_FACTOR - (image.width / SCALE_FACTOR) * ratio
  const y = STAGE_CONFIG.height / SCALE_FACTOR - (image.height / SCALE_FACTOR) * ratio

  const width = image.width * ratio
  const height = image.height * ratio

  return {
    x,
    y,
    width,
    height,
  }
}

export const download = (uri: string, name: string): void => {
  const link = document.createElement("a")

  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
