import React, { useRef, useState } from 'react';
import type Konva from 'konva';
import { Stage, Layer, Text, Image } from 'react-konva';
import useImage from 'use-image';
import { rem } from 'polished';
import styled from 'styled-components';
import CircleLoader from 'react-spinners/CircleLoader';
import { css } from '@emotion/react';

import { download } from '../helpers/utils';

import { STAGE_CONFIG, TWITTER_API_ADDRESS } from '../helpers/const';

import Figure from '../components/Figure';
import Button from '../components/Button';
import { FiTwitter, FiDownload } from 'react-icons/fi';

/**
 * Types
 */
interface Props {
  portrait?: string;
  twitterID?: string;
  cardDescription: string;
  setPopup: any;
}

interface WrapperProps {
  preview?: string;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  transform: translate3d(0, 0, 0);

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-bottom: 100% !important;
  }

  .stage {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .konvajs-content {
    width: 100% !important;
    height: 0px !important;
    padding-bottom: 100% !important;

    canvas {
      width: 100% !important;
      height: 100% !important;
      border-radius: 20px;
      object-fit: contain;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${Button} {
    background-color: ${(props) => props.theme.colors.white};
    padding: ${rem(10)} ${rem(18)};
    font-size: ${rem(16)};
    height: ${rem(44)};

    &:active {
      background-color: ${(props) => props.theme.colors.dark};
      color: ${(props) => props.theme.colors.white};
    }
  }

  @media all and (max-width: 580px) {
    left: 12px;
    bottom: 12px;
    right: 12px;

    ${Button} {
      height: 36px;
      font-size: 14px;
      line-height: normal;
      width: auto;

      span,
      i {
        line-height: normal;
      }
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  ${Button} {
    margin: 10px;
  }
`;

const TwitterProfileImage = ({ twitterID }: any) => {
  console.log(twitterID, typeof twitterID);
  const image_source = TWITTER_API_ADDRESS + twitterID;
  const [twitterImage] = useImage(
    image_source || '/images/sample-profile.jpeg',
    'anonymous'
  );
  if (!twitterImage) {
    return <></>;
  }
  return (
    <Image
      image={twitterImage}
      x={250}
      y={265}
      offset={{ x: 100, y: 100 }}
      width={200}
      height={200}
      cornerRadius={100}
    />
  );
};

const RandomCardDescription = ({ text }: any) => {
  return (
    <Text
      text={text}
      fill="white"
      align="center"
      x={250}
      y={400}
      width={140}
      offsetX={70}
      fontFamily="Mirza"
      fontSize={15}
    />
  );
};


const Sandbox: React.FC<Props> = ({
  twitterID,
  cardDescription,
  setPopup,
}: Props) => {
  const [twitterImage] = useImage(
    TWITTER_API_ADDRESS + twitterID || TWITTER_API_ADDRESS + 'YoungjaeJang99',
    'anonymous'
  );
  const stageRef = useRef(null);

  const onExport = async () => {
    if (stageRef?.current) {
      // eslint-disable-next-line
      (stageRef.current as Konva.Node).toImage({
        pixelRatio: 3,
        callback(img) {
          download(img.src, 'soullink-ticket.png');
        },
      });
    }
  };

  const override = css`
    position: relative;
    z-index: 1;
  `;

  let twitterIDSize = 0;
  if (twitterID && twitterID.length < 11) twitterIDSize = 25;
  else if (twitterID && twitterID.length < 16) twitterIDSize = 20;
  else twitterIDSize = 18;

  const openTwitter = () => {
    // window.open(
    //   `${
    //     process.env.REACT_APP_TEST_API || '/'
    //   }api/rendering?username=${twitterID}&text=${encodeURIComponent(
    //     cardDescription
    //   )}`
    // );
    const newPageUrl = `${
      process.env.REACT_APP_TEST_API || '/'
    }api/rendering?username=${twitterID}&text=${encodeURIComponent(
      cardDescription
    )}`
    window.open(newPageUrl, "_blank") //to open new page
    // navigate(`${
    //   process.env.REACT_APP_TEST_API || '/'
    // }api/rendering?username=${twitterID}&text=${encodeURIComponent(
    //   cardDescription
    // )}`);
    setPopup();
    console.log('open twitter');
  };

  return (
    <>
      {!twitterImage ? (
        <div
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            paddingBottom: '100% !important',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <CircleLoader
            color={'#ffffff'}
            css={override}
            loading={true}
            size={100}
          />
        </div>
      ) : (
        <>
          <Wrapper>
            <Stage className="stage" {...STAGE_CONFIG} ref={stageRef}>
              <Layer>
                <Figure
                  scaled
                  src={'images/soullinker-card/elements/background-2.jpg'}
                />
                {twitterImage ? (
                  <TwitterProfileImage twitterID={twitterID} />
                ) : (
                  <Text
                    text="loading..."
                    fontFamily="Mirza"
                    fontSize={20}
                    fill="white"
                    x={250}
                    y={360}
                  />
                )}

                <Figure
                  scaled
                  src={'images/soullinker-card/elements/form-name.png'}
                />
                <Text
                  text={'@' + twitterID}
                  fill="#3131b0"
                  x={250}
                  y={355}
                  align="center"
                  fontFamily="Mirza"
                  fontSize={twitterIDSize}
                  width={160}
                  offsetX={80}
                />
                <RandomCardDescription text={cardDescription} />
              </Layer>
            </Stage>
          </Wrapper>
          <Actions>
            <ButtonGroup>
              <Button
                as="a"
                target="_blank"
                rel="noreferrer"
                onClick={openTwitter}
                // href={`${
                //   process.env.REACT_APP_TEST_API || '/'
                // }api/rendering?username=${twitterID}&text=${encodeURIComponent(
                //   cardDescription
                // )}`}
              >
                <FiTwitter />
                <span>Share</span>
              </Button>
              <Button type="button" onClick={onExport}>
                <FiDownload />
                <span>Download</span>
              </Button>
            </ButtonGroup>
          </Actions>
        </>
      )}
    </>
  );
};

export default Sandbox;
