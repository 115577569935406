import styled from 'styled-components';
import { rem } from 'polished';

/**
 * Types
 */
// eslint-disable-next-line
export interface ButtonProps {}

const Button = styled.button<ButtonProps>`
  position: relative;
  background-color: white;
  color: black;
  border: 1px solid rgb(162, 165, 191);
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "IBM Plex Sans", sans-serif;

  text-transform: uppercase;
  transition:  0.3s ease 0s;
  border-radius: 3px;

  //opacity: 0.7;
  /* font-family: 'IBM Plex Sans', sans-serif; */
  /* line-height: 100%; */

  &:hover {
    border: none;
    color: black;
    background-color: ${(props) => props.theme.colors.primary};
  }

  svg{
    margin-right: 10px;
  }

  i {
    font-size: ${rem(48)};
    margin: ${rem(-7)} ${rem(15)} ${rem(-7)} ${rem(-15)};
  }

  i,
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  @media all and (max-width: 580px) {
    //border-radius: 12px;
    padding: 8px 16px;
    width: 100%;
    font-size: 14px;
    line-height: normal;

    i {
      font-size: 32px;
    }
  }
`;

export default Button;
