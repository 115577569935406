export const STAGE_CONFIG = {
  width: 500,
  height: 500,
  draggable: false,
  listening: false, // NOTE(minho): On mobile device env, user can't scroll easily within the konva image area. (but that's almost full part of screen.)
};

export const TWITTER_API_ADDRESS = (process.env.REACT_APP_TEST_API || "/") + "api/twitter?username="

export const SCALE_FACTOR = 2;
export const TWITTER_IMAGE_SIZE = 170;
export const TWITTER_IMAGE_COORDINATE = { x: 205, y: 150 };
export const CARD_DESCRIPTIONS = [
  'Living on the moon',
  'Sci-fi geek',
  'Geeky and Cheeky',
  'AI’s love me',
  'Made on mars',
  'See you in space ^.^',
  'AI + Me = Soul Fiction',
  'Meet me in the metaverse ;)',
  'AILOVEU <3',
  'I’m with Soul Fiction',
  'Soul Fiction > Reality',
];
