import React from 'react';
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import Playground from "../containers/Playground";
import Main from "../components/Main";
import { useSetState } from "../helpers/hooks";

import { CARD_DESCRIPTIONS, TWITTER_API_ADDRESS } from "../helpers/const";

interface State {
  twitterID?: string;
  cardDescription: string;
}

export default function MainPage() {
  const [state, setState] = useSetState<State>({
    twitterID: "SoulFictionNFT",
    cardDescription: "AI + Me = Soul Fiction",
  });

  const onSubmit = (twitterID: string): any => {
    console.log("onSubmit");
    fetch(TWITTER_API_ADDRESS + twitterID).then((response) => {
      console.log(response);
      if (response.status === 200) {
        const cardDescription: string =
          CARD_DESCRIPTIONS[
            Math.floor(Math.random() * CARD_DESCRIPTIONS.length)
          ];
        setState({
          twitterID: twitterID,
          cardDescription: cardDescription,
        });
      } else {
        setState({
          twitterID: undefined,
          cardDescription: "please check your twtiter ID",
        });
      }
    });
  };

  return (
    <Main>
      <Header />
      <Playground
        twitterID={state.twitterID}
        isValidID={state.twitterID !== undefined}
        cardDescription={state.cardDescription}
        onSubmit={onSubmit}
      />
      <Footer />
    </Main>
  );
}
