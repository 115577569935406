import React, { useRef } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import Text from "../components/Text";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import { useState } from "react";

/**
 * Types
 */
interface Props {
  onSubmit: (twitterID: string) => void;
  isValidID: boolean;
}
// eslint-disable-next-line
interface WrapperProps {}

const Wrapper = styled.div<WrapperProps>`
  text-align: center;
  margin-left: 20px;

  @media all and (max-width: 1024px) {
    margin-bottom: 30px;
  }

  @media all and (max-width: 580px) {
    margin-bottom: 16px;
    margin-left: 0px;

    /* button i {
      position: absolute;
      left: 4px;
      top: 0;
      margin: 0;
    } */
  }
`;

const ButtonWrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 10px;

  @media all and (max-width: 1024px) {
    flex-direction: column;
  }

  @media all and (max-width: 580px) {
  }
`;

const IDInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  color: red;
`

const Info: React.FC<Props> = ({ onSubmit, isValidID }: Props) => {
  const { getInputProps } = useDropzone({});

  const twitterIDInput = useRef(null);
  const [twitterID, setTwitterID] = useState("");

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      onSubmit(twitterID);
    }
  };

  return (
    <Wrapper>
      <Text>
        The Soulink is an AI generated NFT that is the key to building a
        collective digital consciousness that will live in the metaverse.
        <br />
        <br />
        You, the Soul, must hold at least 1 Soulink to become a Soulinker.
        <br />
        <br />
        We are patiently waiting for you, Soulinker. When the time comes, we
        will embark on our journey through time and space together.
      </Text>

      <ButtonWrapper>
        <IDInputContainer>
          <TextInput
            placeholder="Enter your twitter ID"
            value={twitterID}
            ref={twitterIDInput}
            onChange={(e) => setTwitterID(e.target.value)}
            onKeyPress={handleKeyPress}
            isValid={isValidID}
          />
          {isValidID ? null : <p>Invalid twitter ID!</p>}
        </IDInputContainer>

        <Button onClick={() => onSubmit(twitterID)}>
          <span>Generate</span>
          <input {...getInputProps()} accept="image/*" />
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Info;
