import styled from "styled-components"
import { rem } from "polished"

/**
 * Types
 */
// eslint-disable-next-line
export interface TitleProps {}

const Title = styled.h1<TitleProps>`
  margin-bottom: 0;
  letter-spacing: 0;
  line-height: 138%;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => rem(props.theme.fontSize.h1)};
  font-size: 5vw;
  font-family: ${(props) => props.theme.font.headings};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};

  @media all and (max-width: 767px) {
    // font-size: ${(props) => rem(props.theme.fontSize.h2)};
    font-size: 6vw;
  }

  @media all and (max-width: 580px) {
    font-size: 25px;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 4px;
  }
`

export default Title
