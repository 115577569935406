import React from 'react';
import Main from '../components/Main'
import BoardContainer from '../containers/board/BoardContainer'
import Header from '../containers/Header'

export default function Board() {
  return (
    <Main>
        <Header/>
        <BoardContainer/>
    </Main>
  )
}