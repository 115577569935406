import React from "react";
import { Routes, Route } from "react-router-dom";

import { Layout } from "../layouts/";

import MainPage from "../pages/main";
import Board from "../pages/board";

const App: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="board" element={<Board/>} />
      </Routes>
    </Layout>
  );
};

export default App;
