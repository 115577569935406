import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

initializeApp({
  apiKey: "AIzaSyBuIaHjg2nL4ZIYVlBXsoPDMkTTtBURyQQ",
  authDomain: "soulfiction-denverevent.firebaseapp.com",
  databaseURL: "https://soulfiction-denverevent-default-rtdb.firebaseio.com",
  projectId: "soulfiction-denverevent",
  storageBucket: "soulfiction-denverevent.appspot.com",
  messagingSenderId: "1018116319304",
  appId: "1:1018116319304:web:4aa36830c33229ae40907d",
  measurementId: "G-KJMJYVSETY",
});

export const db = getFirestore();
