import React, { useState } from 'react';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { FluidGrid } from '../layouts/global-styles';

import Section from '../components/Section';
import Card from '../components/Card';
import GleamModal from '../components/Modal';

import Info from './Info';
import Sandbox from './Sandbox';

/**
 * Types
 */
interface Props {
  twitterID?: string;
  isValidID: boolean;
  cardDescription: string;
  onSubmit: (twitterID: string) => void;
}

const StyledSection = styled(Section)`
  @media all and (min-width: 1025px) {
    flex: 1;

    ${Grid}, ${Card}, ${Row} {
      height: 100%;
    }

    #my-content {
      display: none;
    }
  }
`;

const Playground: React.FC<Props> = ({
  twitterID,
  isValidID,
  cardDescription,
  onSubmit,
}: Props) => {
  const [popupOpen, setPopup] = useState(false);

  const openPopup = () => {
    setPopup(true);
  };

  return (
    <StyledSection>
        {/* {popupOpen && <GleamModal close={() => {setPopup(false);}}/>} */}
        <Grid as={FluidGrid} fluid>
          <Card>
            <Row middle="sm" reverse={true}>
              <Col xs={12} md={6}>
                <Info onSubmit={onSubmit} isValidID={isValidID} />
              </Col>
              <Col xs={12} md={6}>
                <Sandbox
                  twitterID={twitterID}
                  cardDescription={cardDescription}
                  setPopup={() => openPopup()}
                />
              </Col>
            </Row>
          </Card>
        </Grid>
    </StyledSection>
  );
};

export default Playground;
